define("discourse/plugins/chat/discourse/components/chat/composer/button", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/template-factory", "@ember/component"], function (_exports, _component, _dIcon, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatComposerButton extends _component.default {}
  _exports.default = ChatComposerButton;
  _class = ChatComposerButton;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-composer-button__wrapper">
        <button type="button" class="chat-composer-button" ...attributes>
          {{dIcon @icon}}
        </button>
      </div>
    
  */
  {
    "id": "NWeXnlpp",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-composer-button__wrapper\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,4,\"button\"],[24,0,\"chat-composer-button\"],[17,1],[12],[1,\"\\n        \"],[1,[28,[32,0],[[30,2]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@icon\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/composer/button.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), _class);
});