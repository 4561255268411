define("discourse/plugins/chat/discourse/components/chat-emoji-avatar", ["exports", "@glimmer/component", "discourse/helpers/replace-emoji", "@ember/template-factory", "@ember/component"], function (_exports, _component, _replaceEmoji, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _class extends _component.default {}
  _exports.default = _class;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-emoji-avatar">
        <div class="chat-emoji-avatar-container">
          {{replaceEmoji @emoji}}
        </div>
      </div>
    
  */
  {
    "id": "4TigbuGj",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-emoji-avatar\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-emoji-avatar-container\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[[30,1]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@emoji\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-emoji-avatar.js",
    "scope": () => [_replaceEmoji.default],
    "isStrictMode": true
  }), _class);
});