define("discourse/plugins/chat/discourse/components/chat-side-panel-resizer", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _class extends _component.default {}
  _exports.default = _class;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-side-panel-resizer"></div>
    
  */
  {
    "id": "rg/8s8Lu",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-side-panel-resizer\"],[12],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-side-panel-resizer.js",
    "isStrictMode": true
  }), _class);
});