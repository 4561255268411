define("discourse/plugins/chat/discourse/components/chat/message-creator/list-action", ["exports", "@glimmer/component", "discourse/components/d-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _dButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ListAction extends _component.default {}
  _exports.default = ListAction;
  _class = ListAction;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DButton
        class="btn btn-flat"
        @icon={{@item.icon}}
        @translatedLabel={{@item.label}}
      />
    
  */
  {
    "id": "WBjV9LjH",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn btn-flat\"]],[[\"@icon\",\"@translatedLabel\"],[[30,1,[\"icon\"]],[30,1,[\"label\"]]]],null],[1,\"\\n  \"]],[\"@item\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/list-action.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _class);
});