define("discourse/plugins/chat/discourse/components/chat-composer-message-details", ["exports", "@glimmer/component", "discourse/components/d-button", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse-common/helpers/html-safe", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/template-factory", "@ember/component"], function (_exports, _component, _dButton, _replaceEmoji, _dIcon, _htmlSafe, _chatUserAvatar, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatComposerMessageDetails extends _component.default {}
  _exports.default = ChatComposerMessageDetails;
  _class = ChatComposerMessageDetails;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class="chat-composer-message-details"
        data-id={{@message.id}}
        data-action={{if @message.editing "edit" "reply"}}
      >
        <div class="chat-reply">
          {{dIcon (if @message.editing "pencil-alt" "reply")}}
          <ChatUserAvatar @user={{@message.user}} />
          <span class="chat-reply__username">{{@message.user.username}}</span>
          <span class="chat-reply__excerpt">
            {{replaceEmoji (htmlSafe @message.excerpt)}}
          </span>
        </div>
  
        <DButton
          @action={{@cancelAction}}
          @icon="times-circle"
          @title="cancel"
          class="btn-flat cancel-message-action"
        />
      </div>
    
  */
  {
    "id": "xFonk8zk",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-composer-message-details\"],[15,\"data-id\",[30,1,[\"id\"]]],[15,\"data-action\",[52,[30,1,[\"editing\"]],\"edit\",\"reply\"]],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-reply\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[[52,[30,1,[\"editing\"]],\"pencil-alt\",\"reply\"]],null]],[1,\"\\n        \"],[8,[32,1],null,[[\"@user\"],[[30,1,[\"user\"]]]],null],[1,\"\\n        \"],[10,1],[14,0,\"chat-reply__username\"],[12],[1,[30,1,[\"user\",\"username\"]]],[13],[1,\"\\n        \"],[10,1],[14,0,\"chat-reply__excerpt\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[[28,[32,3],[[30,1,[\"excerpt\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,4],[[24,0,\"btn-flat cancel-message-action\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,2],\"times-circle\",\"cancel\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@message\",\"@cancelAction\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-composer-message-details.js",
    "scope": () => [_dIcon.default, _chatUserAvatar.default, _replaceEmoji.default, _htmlSafe.default, _dButton.default],
    "isStrictMode": true
  }), _class);
});