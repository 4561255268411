define("discourse/plugins/chat/discourse/components/chat/composer/separator", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatComposerSeparator extends _component.default {}
  _exports.default = ChatComposerSeparator;
  _class = ChatComposerSeparator;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-composer-separator"></div>
    
  */
  {
    "id": "wfT6wRFQ",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-composer-separator\"],[12],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/composer/separator.js",
    "isStrictMode": true
  }), _class);
});