define("discourse/plugins/chat/discourse/services/chat-channel-composer", ["exports", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/chat/discourse/models/chat-message"], function (_exports, _tracking, _object, _service, _chatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelComposer = _exports.default = (_dec = (0, _service.inject)("chat-thread-composer"), (_class = class ChatChannelComposer extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "threadComposer", _descriptor5, this);
      _initializerDefineProperty(this, "loadingSlider", _descriptor6, this);
      _initializerDefineProperty(this, "message", _descriptor7, this);
      _initializerDefineProperty(this, "textarea", _descriptor8, this);
    }
    focus() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.textarea?.focus(options);
    }
    blur() {
      this.textarea.blur();
    }
    reset(channel) {
      this.message = _chatMessage.default.createDraftMessage(channel, {
        user: this.currentUser
      });
    }
    cancel() {
      if (this.message.editing) {
        this.reset(this.message.channel);
      } else if (this.message.inReplyTo) {
        this.message.inReplyTo = null;
      }
      this.focus({
        ensureAtEnd: true,
        refreshHeight: true
      });
    }
    edit(message) {
      this.chat.activeMessage = null;
      message.editing = true;
      this.message = message;
      this.focus({
        refreshHeight: true,
        ensureAtEnd: true
      });
    }
    async replyTo(message) {
      this.chat.activeMessage = null;
      if (message.channel.threadingEnabled) {
        if (!message.thread?.id) {
          this.loadingSlider.transitionStarted();
          const threadObject = await this.chatApi.createThread(message.channel.id, message.id);
          this.loadingSlider.transitionEnded();
          message.thread = message.channel.threadsManager.add(message.channel, threadObject);
        }
        this.reset(message.channel);
        await this.router.transitionTo("chat.channel.thread", ...message.thread.routeModels);
        this.threadComposer.focus({
          ensureAtEnd: true,
          refreshHeight: true
        });
      } else {
        this.message.inReplyTo = message;
        this.focus({
          ensureAtEnd: true,
          refreshHeight: true
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "threadComposer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadingSlider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "message", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "textarea", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "focus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blur", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "blur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replyTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "replyTo"), _class.prototype)), _class));
});