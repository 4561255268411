define("discourse/plugins/chat/discourse/services/chat-drafts-manager", ["exports", "@ember/service", "discourse/plugins/chat/discourse/models/chat-message"], function (_exports, _service, _chatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class ChatDraftsManager extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "drafts", {});
    }
    add(message) {
      if (message instanceof _chatMessage.default) {
        this.drafts[message.channel.id] = message;
      } else {
        throw new Error("message must be an instance of ChatMessage");
      }
    }
    get(_ref) {
      let {
        channelId
      } = _ref;
      return this.drafts[channelId];
    }
    remove(_ref2) {
      let {
        channelId
      } = _ref2;
      delete this.drafts[channelId];
    }
    reset() {
      this.drafts = {};
    }
  }
  _exports.default = ChatDraftsManager;
});